@import '../../base/base';
body.dark {

.bs-stepper-content {
  width: 100%;
}

.bs-stepper .step.crossed + .line {
  background-color: $primary;
}

.step.crossed .step-trigger {
  &.disabled .bs-stepper-circle, &:disabled .bs-stepper-circle {
    background-color: $primary;
    color: #fff;
  }
}

.bs-stepper .line, .bs-stepper-line {
  background-color: rgb(255 255 255 / 12%);
}

.bs-stepper-circle {
  background-color: #bfc9d4;

  svg {
    width: 16px;
    height: 16px;
  }
}

.bs-stepper .step-trigger {
  color: #bfc9d4;
  font-weight: 200;
  letter-spacing: 1px;

  &.disabled, &:disabled {
    opacity: .45;
  }

  &.disabled .bs-stepper-circle, &:disabled .bs-stepper-circle {
    color: #000;
    font-weight: 700;
  }
}

.active .bs-stepper-circle {
  background-color: $primary;
}

.bs-stepper-label:focus {
  color: $primary;
}

}

/* 
    ================
        Vertical
    ================
*/

body.dark {
.bs-stepper.vertical {
  .bs-stepper-header {
    display: block;
  }

  .step-trigger {
    padding: 0;
    padding-bottom: 15px;
  }

  .bs-stepper-content .content:not(.active) {
    display: none;
  }

  .line {
    width: 1px;
    height: 25px;
    margin-bottom: 15px;
  }
}

.vertical .bs-stepper-line {
  width: 1px;
  height: 25px;
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .bs-stepper-header {
    display: block;
  }

  .bs-stepper {
    &.vertical {
      display: block;
    }

    .line {
      display: none;
    }
  }

  .bs-stepper-line {
    display: none;
  }

  .bs-stepper .step-trigger {
    padding: 8px 0;
  }

  .bs-stepper-content {
    padding: 0;
    padding: 20px 0 0;
  }
}

}